@import './reset';
@import './variables';
@import './mixins';
@import './grid';
@import './animations';

html {
  font-size: 62.5%;
}

body {
  font-family: $font-main;
  font-size: 1.4rem;
  color: $color-text-gray;
  font-weight: $font-weight-regular;
  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // override simplebar style
  .simplebar-track {
    &.simplebar-vertical {
      width: 8px;
    }

    &.simplebar-horizontal {
      .simplebar-scrollbar {
        height: 6px;
      }
    }
  }

  .simplebar-scrollbar {
    &:before {
      background-color: $color-bg-simple-bar;
      left: 0;
    }

    &.simplebar-visible {
      &:before {
        opacity: 1;
      }
    }
  }
}

@font-face {
  @include fontface($font-family-bouygues-read, $font-weight-light);
}
@font-face {
  @include fontface($font-family-bouygues-read, $font-weight-regular);
}
@font-face {
  @include fontface($font-family-bouygues-read, $font-weight-medium);
}
@font-face {
  @include fontface($font-family-bouygues-read, $font-weight-semi-bold);
}
@font-face {
  @include fontface($font-family-bouygues-read, $font-weight-bold);
}
@font-face {
  @include fontface($font-family-bouygues-speak, $font-weight-bold);
}
@font-face {
  @include fontface($font-family-roboto, $font-weight-regular);
}
@font-face {
  @include fontface($font-family-roboto, $font-weight-medium);
}

.keywords-highlight {
  background-color: $color-highlight-light;
}

// use for temporarly hidden elements
#print_app .tmp-hidden {
  display: none;
}
