.loader {
  &::before {
    content: '';
    display: block;
    width: 71px;
    height: 71px;
    background-size: cover;
    animation: loader-spin 1s linear infinite;
  }

  width: 71px;
  height: 71px;

  &--fit,
  &--fullscreen {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    &.loader--black {
      background-color: rgba(255, 255, 255, 0.5);
    }
    &.loader--white {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &--fit {
    position: absolute;
  }

  &--fullscreen {
    position: fixed;
  }
}

.loader--black::before {
  background-image: url(../../../images/loader-black.png);
}

.loader--white::before {
  background-image: url(../../../images/loader-white.png);
}

.loader--blue::before {
  background-image: url(../../../images/loader-blue.png);
}

@keyframes loader-spin {
  100% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}
