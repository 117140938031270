// Colors
$color-white: #fff;
$color-black: #000;
$color-main: #109db9;
$color-text: #0e4e66;
$color-text-50: rgba(14, 78, 102, 0.5);
$color-text-dark: hsl(197, 100%, 20%);
$color-text-gray: #878b92;
$color-text-gray-dark: #5f6369;
$color-highlight: #27465e;
$color-highlight-dark: #02455e;
$color-highlight-light: #ffffaa;
$color-bg-light: #f2f2f3;
$color-bg-extra-light: #fafafa;
$color-placeholder: #a7a7a7;
$color-dark: #181a1b;
$color-border: #dbdce0;
$color-warning: #ff554b;
$color-checked: #009fbc;
$color-bg-checked: #004f69;
$color-bg-upload: #007aa7a8;
$color-bg-upload-left: #002138;
$color-bg-blue-file: #082136;
$color-bg-blue-light: #0054aa;
$color-bg-simple-bar: #d8d8d8;

$color-skeleton: #f2f2f2;
$color-skeleton-active: #e6e6e6;

// Font family
$font-family-bouygues-read: 'BouyguesRead';
$font-family-bouygues-speak: 'BouyguesSpeak';
$font-family-roboto: 'Roboto';
// Font weights
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;
$font-weight-extrablack: 900;

// Font sizes
$font-size-xl: 2rem;
$font-size-l: 1.8rem;
$font-size-m: 1.4rem;
$font-size-s: 1.2rem;
$font-size-xs: 1rem;

// Fonts
$font-main: $font-family-bouygues-read;
$font-main-variant: $font-family-bouygues-speak;
$font-secondary: $font-family-roboto;

// Box Shadow
$box-shadow-input: 0 1px 1px rgba(0, 0, 0, 0.3);
$box-shadow-input-inset: inset 0 1px 1px rgba(0, 0, 0, 0.3);
$box-shadow-block: 0 2px 4px rgba(0, 0, 0, 0.1);

// Border Radius
$border-radius-default: 4px;
$border-radius-block: 6px;
$border-radius-circle: 50%;

// Z-index
$z-index-sidebar: 3;
$z-index-filter: 2;
$z-index-filter-open: 10;
$z-index-filter-open-mb: 11;
$z-index-sidebar-open-mb: 12;
$z-index-top-menu-mb: 13;
$z-index-overlay: 9;

// Screens
$screen-xs: 414px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

$width-container-lg: 1078px;
$width-sidebar-lg: 282px;

$btn-size-small: 26px;

// Print grid
$gutter: 1.2rem;
$prefix: 'print';
