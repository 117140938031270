$path-font: '/fonts';

$font-weights: (
  200: 'ExtraLight',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'SemiBold',
  700: 'Bold',
  800: 'Black',
  900: 'ExtraBlack'
);

@mixin fontface($font-family, $weight) {
  $font-weight: map-get(
    $map: $font-weights,
    $key: $weight
  );
  font-family: $font-family;
  src: url('#{$path-font}/#{$font-family}-#{$font-weight}.woff2')
      format('woff2'),
    url('#{$path-font}/#{$font-family}-#{$font-weight}.woff') format('woff'),
    url('#{$path-font}/#{$font-family}-#{$font-weight}.ttf') format('truetype');
  font-weight: $weight;
}
@mixin screen-xl-up {
  @media (min-width: $screen-xl) {
    @content;
  }
}

@mixin screen-xl-down {
  @media (max-width: $screen-xl - 1) {
    @content;
  }
}
@mixin screen-lg-up {
  @media (min-width: $screen-lg) {
    @content;
  }
}

@mixin screen-lg-down {
  @media (max-width: $screen-lg - 1) {
    @content;
  }
}

@mixin screen-md-up {
  @media (min-width: $screen-md) {
    @content;
  }
}

@mixin screen-md-down {
  @media (max-width: $screen-md - 1) {
    @content;
  }
}

@mixin screen-sm-up {
  @media (min-width: $screen-sm) {
    @content;
  }
}

@mixin screen-sm-down {
  @media (max-width: $screen-sm - 1) {
    @content;
  }
}

@mixin screen-xs-up {
  @media (min-width: $screen-xs) {
    @content;
  }
}

@mixin screen-xs-down {
  @media (max-width: $screen-xs - 1) {
    @content;
  }
}

@mixin screen-container-with-sidebar-lg-down {
  @media (max-width: $width-container-lg + $width-sidebar-lg) {
    @content;
  }
}