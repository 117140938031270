.card-translate {
  transform: translateY(0);
  transition: transform 0.3s ease;
}
.card-translate:hover {
  transform: translateY(-10px);
}

.card-box-shadow {
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
}
.card-box-shadow:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.preview-animation {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 70%;
    cursor: grabbing;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6);
  }
  &--close {
    top: 1rem;
    right: 1rem;
    position: fixed;
    border: 0;
    width: 5rem;
    height: 5rem;
    text-align: center;
    font-size: 1.8rem;
    border-radius: 50%;
    line-height: 5.2rem;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.05);
  }
  &__wrapper {
    overflow: hidden;
  }
}