.#{$prefix}-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;
  margin-right: -$gutter;
}

.#{$prefix}-row > [class*="#{$prefix}-col-"] {
  padding-left: $gutter;
  padding-right: $gutter;
}

@for $i from 1 through 12 {
  .#{$prefix}-row > .#{$prefix}-col-#{$i} {
    flex: 0 0 percentage((1 / 12) * $i);
    max-width: percentage((1 / 12) * $i);
  }
}

@include screen-sm-up {
  @for $i from 1 through 12 {
    .#{$prefix}-row > .#{$prefix}-col-sm-#{$i} {
      flex: 0 0 percentage((1 / 12) * $i);
      max-width: percentage((1 / 12) * $i);
    }
  }
}